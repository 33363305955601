.aui-root .shadow {
    overflow-x: auto;
}

.aui-root .shadow div span {
    display: inline-block;
}


@media (min-width: 1536px) {
    .aui-root .container\:base .panel--right {
        display: none;
    }

    .aui-root .container\:base .panel--center .panel-item--center,
    .aui-root .container\:base .panel--center .panel-item--right {
        width: auto;
    }

    .aui-root .container\:base .panel-item {
        display: block;
    }

    .aui-root .container\:base .examples {
        margin-top: 1rem;
        padding: 1rem;
    }
}