/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
USWDS THEME CUSTOM STYLES
----------------------------------------
!! Copy this file to your project's
   sass root. Don't edit the version
   in node_modules.
----------------------------------------
Custom project SASS goes here.

i.e.
@include u-padding-right('05');
----------------------------------------
*/

@use "uswds-core" as *;

// Override the heading styles.  Yes, the h1 is supposed to be smaller than
// the h2.
h1 {
    @include u-font-size('heading', 'md');
    font-family: family('sans');
    font-weight: font-weight('normal');
}
h2 {
    @include u-font-size('heading', 'lg');
    font-family: family('sans');
    font-weight: font-weight('bold');
}
h3 {
    @include u-font-size('heading', 'md');
    font-family: family('sans');
    font-weight: font-weight('bold');
}
h4 {
    @include u-font-size('heading', 'sm');
    font-family: family('sans');
    font-weight: font-weight('bold');
}

.usa-button {
    /* Unbold the button text, which is bold by default */
    font-weight: 400;

    /* Remove the default right margin */
    margin-right: 0;
}

.usa-input, .usa-textarea, .usa-select {
    /* Close the gap between the input box and the label above it */
    margin-top: 0;

    /* Let the text field be as wide as it wants */
    max-width: none;
}

// Putting "type=search" on the search text field automatically applies
// USWDS styles - which sets the right border to 'none' for some reason
.usa-search__input, [type='search'] {
    border-right: 2px solid color('gray-20') !important;
}

.usa-label, .usa-legend, .usa-step-indicator__segment, .usa-list li,
.usa-prose, .usa-prose > ul li, .grid-container {
    /* Let these be as wide as they want */
    max-width: none !important;
}

// Default style for USWDS modals is column-reverse so that an X button can
// be displayed in the top right corner but be announced last by screen readers.
// We are using our own Close buttons instead of the corner X so set the
// direction to be the normal top-to-bottom.
.usa-modal__content {
    flex-direction: column !important;
}
// Default USWDS uses padding of 4rem which is too much
.usa-modal__main, 
.usa-modal--lg {
    padding-bottom: units(2) !important;
}
.usa-modal--lg {

  .usa-modal__main {
    max-width: units('desktop');
  }

  // USWDS was setting the font of the heading to xl for large modals
  // which didn't look good or consistent with our other content, so
  // keep the font at the same size (lg) as it is for non-large modals
  .usa-modal__heading {
    @include at-media("tablet") {
      @include u-font("heading", "lg");
    }
  }
}
// Without this, modals activated lower down on the page were being
// displayed lower on the page, which meant the user might have to scroll
// to view the modal
.usa-modal-overlay:before {
    height: unset;
}

.usa-sidenav {
    border-bottom: none;

    a {
        color: color('primary') !important;
    }

    .usa-sidenav__item {
        border-left: units(2px) solid color('primary');
        border-top: none;
        border-bottom: none;
        &:focus, &:hover {
            border-left: units('05') solid color('blue-50');
            font-weight: font-weight(bold);
        }
    }
}


// Make the error outline for file input match our other components; 
// the USWDS default is more like a dotted orange for some reason.
.usa-file-input__target.has-invalid-file,
.usa-file-input__instructions.has-invalid-file {
    border: 3px solid color("error-dark");
}

// Make the pagination text just slightly smaller so it is not larger than
// the table data
.usa-pagination, .usa-pagination__link-text, .dip-pagination__text {
    font-size: .9rem !important;
}
// Since this component is not linking to a literal new page, remove the
// default underline upon hover
.usa-pagination__link:hover {
    text-decoration: none !important;
}
// Original overflow color contrast was a Level AA violation; this gets us down
// to Level AAA without the component looking stupid
.usa-pagination__overflow {
    color: black !important;
    font-weight: 900 !important;
    font-size: 1.2rem !important;
}
