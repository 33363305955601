.user-guides-table tbody tr:nth-child(even) td {
    background-color: rgba(227, 233, 237, 0.9);
}

.user-guides-table thead th {
    background-color: #112F63;
    color: #fff;
}

.user-guides-table td {
    border: 0;
}

.user-guides-download-link {
    color: blue;
    text-decoration: underline;
}

.user-guides-download-link:hover {
    cursor: pointer;
}
