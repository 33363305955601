.searchField {
    background-color: white;
    color: black;
    padding: .5rem;
    font-size: 1rem;
    flex-grow: 1;
}

.searchField:focus, .searchField:hover {
    border: 2px solid #122F63;
}
