/* Styling of the skip-navigation componet.  It is hidden from view until
 * it receives the focus.
 */
.skip-nav-link {
    position: absolute;
    left: -9999px;
    z-index: 999;
    padding: .8em;
    background-color: white;
    color: '#0050C9';
    opacity: 0;
}

.skip-nav-link:focus {
    background-color: white;
    color: '#5C0099';
    left: .5em;
    top: 3px;
    opacity: 1;
}
