@charset "UTF-8";

/* Default button color is the Catalog's 'primary' color, 'indigo-cool-70' */
.swagger-ui .btn {
  background: #374274;
  border-color: #374274;
  color: #ffffff;
}
.swagger-ui .btn.execute {
  background: #374274;
  border-color: #374274;
  color: #ffffff;
}
/* Cancel button color is the Catalog's 'secondary' color, 'orange-warn-70' */
.swagger-ui .btn.cancel {
  background: #633a32;
  border-color: #633a32;
  color: #ffffff;
}
/* Clear button color is your basic black-on-white */
.swagger-ui .btn.btn-clear {
  background: #ffffff;
  color: #000000;
}

/* Color of the badge for the service version */
.swagger-ui .info .title small {
  background: #336e7b;
}

/* Color of the badge for the OpenAPI spec version */
.swagger-ui .info .title small.version-stamp {
  background: #4a771a;
}

/* In the Responses block, the Media Type selector and label */
.swagger-ui .response-control-media-type--accept-controller select {
  border-color: #112F63;
}
.swagger-ui .response-control-media-type__accept-message {
  color: #000;
}

/* Input/output schema names */
.swagger-ui .model-title {
  word-break: break-all;
}

/* Container for the input/output schemas */
.swagger-ui section.models .model-container {
  overflow-x: auto;
}

/* Holds the pathname of a GET/POST/etc */
.swagger-ui .opblock .opblock-summary-path {
  white-space: nowrap;
}

/* Hide the server select element */
.swagger-ui .scheme-container {
  display: none;
}


@media only screen and (max-width: 600px) {
  .swagger-ui .opblock .opblock-section-header {
    overflow-x: auto;
  }

  .swagger-ui table {
    display: block;
    overflow-x: auto;
  }
}
