@use "uswds-core" as *;

//
// Customization of the site header.  A lot of this is overriding the default
// USWDS styling, but also has our own customizations
//

// Height of the DIP and NASA logos on the menu bar
$logo-height: 6;

// The overlay is what blocks out the main screen when the mobile menu
// is opened.  This is copied from usa-overlay just to be a little cleaner
.site-overlay {
    @include u-pin("all");
    position: fixed;
    background: color('black-transparent-70');
    opacity: opacity(0);
    transition: opacity $project-easing;
    visibility: hidden;
    z-index: z-index(400);

    &.is-visible {
        opacity: opacity(100);
        visibility: visible;
    }
}

// Container for the logo and the navbar
.site-nav-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: none;
}

// Logo on the top left corner
.site-logo {

    display: flex;
    flex: 0 0 auto;
    align-items: center;
    margin: units(0) !important;

    img {
        @include u-height($logo-height);
        width: auto;

        &:hover {
            border: units('2px') solid color('white');
        }
    }
}

// NASA logo on the top right corner
.nasa-logo {

    display: inline-block;
    margin: units(0) !important;

    @include u-maxh($logo-height);
}

.site-header {

    // Make the header our standard blue color
    background: color('primary');

    // Slim gray line on the bottom of the header
    border-bottom: units('2px') solid color('gray-50');

    // For the desktop menu, we want the underline on highlight to be white
    .usa-nav__link:hover {
        @include at-media($theme-header-min-width) {
            @include add-bar(2px, "white", "bottom", 0, 1.5, 1);
        }
    }
}

// USWDS by default has the mobile menu on the right.  We are putting
// it on the left to be under the mobile menu 'hamburger' icon
.usa-nav {
    @include at-media-max($theme-header-min-width) {
        @include u-pin('left');

        &.is-visible {
          animation: slidein-right 0.3s ease-in-out;
        }
    }
}

.usa-header--basic {
    // Remove the default padding on the navbar for the desktop menu
    .usa-nav {
        @include at-media($theme-header-min-width) {
            padding: 0;
            justify-content: space-between;
        }
    }

    // On the desktop menu, evenly space the menu items
    .usa-nav__primary {
        @include at-media($theme-header-min-width) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            max-width: none;
        }
    }

    // On the desktop menu, put a gap between the main menu items and the
    // profile menu
    .usa-nav__primary-item:last-of-type {
        @include at-media($theme-header-min-width) {
            margin-left: units(6)
        }
    }
}

// These make sure the news bell icon is aligned with its menu items
.usa-nav__primary .usa-accordion__button span,
.usa-nav__submenu .usa-nav__submenu-item a {
    display: flex;
    align-items: center;
}

// This is the bell icon which is part of the navigation menu.
// Its color has to change based on whether it is on the main menu 
// (yellow on dark background) or mobile menu (gold on light background)
.bell-icon {
    color: color('gold-20v');
    margin-left: units('05');
    @include at-media-max($theme-header-min-width) {
        color: color('yellow-50v');
    }
}
// This is the bell icon when the window has been minimized enough to show
// the mobile menu button.  This is only displayed on the main menu bar so
// it is always yellow so that it shows up on the dark background.
.mobile-bell-icon {
    visibility: hidden;
    @include at-media-max($theme-header-min-width) {
        visibility: visible;
        color: color('gold-20v');
    }
}
// Don't show the mobile menu icon at all when the window is expanded
.mobile-menu {
    display: none;
    @include at-media-max($theme-header-min-width) {
        display: flex;
        align-items: center;
    }
}
// Need to override USWDS default margins for the mobile menu button
.mobile-menu-button {
    margin-left: units(1);
    margin-right: units(0) !important;
}

// Used by the mobile menu button and the DIP logo to keep them grouped
.nav-sub-container {
    display: flex;
    align-items: center;
    margin-right: units(2);
}

// 'primary' is the main navigation menu
.usa-nav__primary {

    margin: 0;
    padding: 0;

    > .usa-nav__primary-item {
        @include at-media-max($theme-header-min-width) {
            line-height: line-height($theme-header-font-family, 1);
        }
        @include at-media($theme-header-min-width) {
            margin-top: units('05');
            margin-left: units(1);
            margin-right: units(1);
        }
        font-size: font-size($theme-header-font-family, "md");
        > a {
            padding: units(1.5);
            font-weight: font-weight('normal') !important;
            &:hover {
                font-weight: font-weight('bold') !important;
            }
        }
    }

    // A button is the control to show a submenu.  Make it be bold on hover to
    // match the other menu items
    button {
        padding: units(1.5);
        font-size: font-size($theme-header-font-family, "md");
        font-weight: font-weight('normal') !important;
        &:hover {
            font-weight: font-weight('bold') !important;
        }
    }
    // When the submenu is open, change the button background color to match
    // the background of the submenu items
    button[aria-expanded="true"] {
        @include at-media($theme-header-min-width) {
            background-color: color("indigo-cool-80");
        }
    }
    // Make sure the chevron after the submenu name is the same color
    // as the text
    button[aria-expanded="false"] span::after,
    button[aria-expanded="false"]:hover span::after,
    button[aria-expanded="true"] span::after {
        @include at-media($theme-header-min-width) {
            background-color: color("white");
        }
    }

    // Make the logout menu item, which is a button, look like the other
    // menu items which are links
    .logout-button {
        &:focus {
            outline-offset: -0.25rem;
        }
        
        width: 100%;
        font-weight: font-weight('normal') !important;
        font-size: font-size($theme-header-font-family, "md") !important;
        @include at-media-max($theme-header-min-width) {
            color: color('primary');
            margin: 0;
            padding-left: units(4) !important;
            &:active {
                color: color('white');
            }
            &:hover {
                font-weight: font-weight('bold') !important;
            }
        }
        @include at-media($theme-header-min-width) {
            color: color('white');
            margin: units('05');
            padding: units('05');
            &:active {
                color: color('white');
            }
            &:hover {
                font-weight: font-weight('bold') !important;
                color: color('white');
            }
        }
    }
}

// Submenus are the menus off the main menu, e.g. Support and Profile
.usa-nav__submenu {
    // Override USWDS width to be just the width of the item
    width: auto;
    // Mobile menu - bold the item when hovered
    @include at-media-max($theme-header-min-width) {
        > .usa-nav__submenu-item {
            &:hover {
                font-weight: font-weight('bold') !important;
            }
        }
    }
    // Dekstop menu - color it blue, bold it when hovered
    @include at-media($theme-header-min-width) {
        background-color: color("indigo-cool-80");
        font-size: font-size($theme-header-font-family, "md");
        > .usa-nav__submenu-item {
            &:hover {
                font-weight: font-weight('bold') !important;
                background-color: color("indigo-cool-60");
            }
        }
    }

    // Make sure there is no underline on hover
    .usa-nav__submenu-item a {
        font-size: font-size($theme-header-font-family, "md");
        &:hover {
            text-decoration: none;
            @include remove-bar();
        }
    }
}

// Use the menu icon instead of "MENU" text in the mobile menu button
.usa-menu-btn {
    @include u-square(4);
    background-image: url("../../icon/menu_white.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin-right: units(1.5);
}

// Remove excess white space below the mobile menu's close button
.usa-nav__close {
    margin-bottom: 0;
}
